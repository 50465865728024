<template>
    <div class="followBox">
        <!-- <Header></Header> -->
        <div class="followList">
            <div class="infinite-list-wrapper" :style="followList.length == 0 ? 'height: 72vh;':''">
                <!-- <ul class="list" v-infinite-scroll="load" infinite-scroll-disabled="disabled"> -->
                <van-empty :image="require('@/assets/null.svg')" v-if="followList.length == 0"/>
                <van-list
                v-else
                class="list"
                v-model="lazyLoading"
                :finished="finished"
                finished-text=""
                @load="onLoad"
                >
                    <div v-for="(item,index) in followList" :key="index" class="list-item" @click="go_schedule(item)">
                        <van-swipe-cell>
                            <!-- <div class="p_title" style="height: 50px;">{{item.meeting_title}}</div> -->
                            <div class="p_title" v-html="item.meeting_title"></div>
                            <div class="bottom">
                                <span class="live_title">{{item.md}}</span>
                                <span class="live_time">{{item.start_at}}-{{item.end_at}}</span>
                                <div style="height:.13rem;width:.6rem;" v-if="item.live_status == 0">预告</div>
                                <div style="height:.13rem;width:.6rem;" v-if="item.live_status == 1">直播中
                                    <img class="img-center" src="@/assets/ing.gif" alt="">
                                </div>
                                <div style="height:.13rem;width:.6rem;" v-if="item.live_status == 2">已结束</div>
                                <!-- <div class="touxiang"><span>{{item.status}} </span></div> -->
                                <span class="go_img"><img src="@/assets/RiCheng/right_arrow.svg" alt=""></span>
                            </div>
                            <template #right> 
                                <van-button @click="delData(item.id)" square type="danger" text="删除" class="delete-button" /> 
                            </template> 
                        </van-swipe-cell>
                    </div>
                <!-- </ul> -->
                </van-list>
                <!-- <p class="p_height" v-if="loading" style="font-size: 12px; width:100%; text-align: center;">加载中...</p>
                <p class="p_height" v-if="noMore" style="font-size: 12px; width:100%; text-align: center;">
                    <img v-if="followList.length == 0" src="../../assets/null.svg" alt="">
                    <span v-else>没有更多了</span>
                </p> -->
            </div>
            <Support></Support>
            <sideBar></sideBar>
        </div>
    </div>
</template>
<script>
import Support from '@/components/support';
import sideBar from '@/components/sidebar';
export default {
    components:{
        sideBar,
        Support
    },
    data(){
        return{
            followList:[],
            collectListData: {
              page: 1,
              page_num: 15
            },
            loading: false,
            total: 0,
            count:0,
            noMore: false,
            lazyLoading:false, //懒加载
            finished:false,
        }
    },
    computed: {
        // disabled () {
        //    return this.loading || this.noMore
        // }
    },
    mounted(){
        this.getFollowList()
        // document.querySelector('.suspend-btn .home-box').style.display = 'none'
    },
    methods: {
        async getFollowList () {
            const res = await this.$store.dispatch('list_follow_the_schedule', this.collectListData)
            if(res.data.code == 200){
                if(Array.isArray(res.data.data)){
                }else{
                    let dataList = res.data.data.data
                    let kongArr = []

                    dataList.forEach(item => {
                        let start_live = item.md + ' ' + item.start_at
                        let end_live = item.md + ' ' + item.end_at
                        if(new Date() < new Date(start_live)){//预告
                            item.live_status = 0
                        }else if(new Date(start_live) < new Date() && new Date() < new Date(end_live)){
                            item.live_status = 1//直播中
                        }else{//结束
                            item.live_status = 2
                        }
                        kongArr.push(item)
                    })
                    if(this.followList.length == 0){
                        this.followList = kongArr
                    }else{
                        this.followList = [...this.followList, ...kongArr]
                    }
                    this.total = res.data.data.total
                    this.count += this.collectListData.page_num
                    this.lazyLoading = false
                }
            }
        },
        // load () {
        //     if(this.followList.length > this.total - 1){
        //         this.loading = true
        //         setTimeout(() => {
        //             this.collectListData.page++
        //             this.getFollowList()
        //         }, 0)
        //     }else{
        //         this.noMore = true
        //     }
        // },
        onLoad() {
            if(this.total > this.count  ){
                this.lazyLoading = true
                setTimeout(() => {
                    this.collectListData.page+=1
                    this.getFollowList()
                }, 1000);
            }else{
                this.finished = true;
            }
        },
        async delData (id) {
            const res = await this.$store.dispatch('delete_follow_the_schedule', id)
            if(res.data.code == 200){
                let arr = []
                this.followList.forEach(item => {
                    if(item.id != id){
                        arr.push(item)
                    }
                })
                this.followList = arr
            }else{
                this.$toast(res.data.message)
            }
        },
        go_schedule(val){
            this.$router.push({path:'/schedule',query: {
                site_id: val.site_id,
                page_id: val.page_id,
                module_id: val.module_id
            }})
        }
    }
}
</script>
<style lang="less" scoped>
    /deep/.van-empty,/deep/.van-loading{
        position: absolute;
        top: 30%;
        left:50%;
        transform:translateX(-50%);
    }
    /deep/.van-empty{
        width: 100%;
        .van-empty__image{
            width: 300px;
            height: 300px;
        }
    }
    /deep/.van-list__finished-text{
        margin-top: 10px;
    }
    /deep/.van-list__loading{
        .van-loading{
            position: relative!important;
        }
    }
/deep/ .van-swipe-cell__right{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-left: .08rem;
    .delete-button {
      height: 0.38rem;
      width: 0.6rem;
      border-radius: .03rem;
      font-size: .12rem;
    }
    /deep/ .van-button--normal{
        padding: 0;
    }
}
.followBox{
    width:100%;
    height:calc(100% - 0.44rem);
    background: #F7F8FA;
    position: relative;
    &::-webkit-scrollbar{
      display: none;
    }
    .followList{
        width:100%;
        height: 100%;
        background: #ffffff;
        overflow: hidden;
        overflow-y: scroll;
        // position: absolute;
        top: 0;     
        position: relative;
        .infinite-list-wrapper{
              min-height: calc(100% - 36px);
            .list{
                border-bottom: 1px solid #e5e5e5;
            }
            .p_height{
            //   height: 0.7rem;
                line-height: 0.7rem;
                font-size: 0.12rem;
                img{
                    position: fixed;
                    top: 47%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: inline-block;
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }
        /deep/ .van-swipe-cell{
            width: 100%;
            .van-swipe-cell__wrapper{
                display: flex;
                flex-direction: column;
            }
        }
        .data_null{
            position: absolute;
            top: 45%;
            left: 50%;
            width: 2.5rem;
            height: 2.5rem;
            img{
                display: block;
                width: 100%;
                height: 100%;
            }
            transform: translate(-50%, -50%);
        }
        
        .list{
            width:94%;
            margin:0 auto;
            .list-item{
                padding: 12px 0px 12px 0px;
                border-bottom: 1px solid #E5E5E5;
                background: #fff;
                .p_title{
                    // height:50px;
                    margin-left: 6px;
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 14px;
                    line-height: 0.24rem;
                    color: #000;
                    display: -webkit-box; 
                    display: -moz-box;  
                    white-space: pre-wrap; 
                    word-wrap: break-word;
                    overflow: hidden; 
                    text-overflow: ellipsis; 
                    -webkit-box-orient: vertical; 
                    -webkit-line-clamp:2;   /*显示行数*/
                }
                .bottom{
                    margin-left: 6px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    height: 24px;
                    line-height: 24px;
                    font-size: 13px;
                    .live_title{
                        width: 25%;
                        color: #888888;
                    }
                    .live_time{
                        width: 40%;
                        color: #888888;
                    }
                    div{
                        width: 20%!important;
                        position: relative;
                        height: 24px!important;
                    }
                    .img-center{
                        position: absolute;
                        top:-0.06rem;
                        left:76%;
                        padding: 3px;
                        transform:translateX(-50%);
                        border-radius: .09rem .09rem .09rem 0;
                        width:.15rem;
                        height:.15rem;
                        background: linear-gradient(222deg, #FF7A66 0%, #FF5B60 100%);
                        margin-top:.02rem;
                    }
                    .touxiang{
                        width: .5rem;
                        height: .3rem;
                        position: relative;
                        margin-right: .05rem;
                        span{
                            color: #000;
                        }
                    }
                    .go_img{
                        width: 10%;
                        color: #888888;
                        text-align: right;
                        padding-right: 10px;
                        img{
                            width: 22px;
                        }
                    }
                }
            }
        }
        .tips{
            width: 100%;
            height: .6rem;
            font-size: .14rem;
            display: block;
            text-align: center;
            line-height: .6rem;
            color: #999;
        }
    }
}

</style>